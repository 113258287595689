import { ActionIcon, Group, Loader, Paper, Select, Text, Title } from "@mantine/core";
import { IconBus, IconClock } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { useState } from "react";
import { forwardRef } from "react";
import { Droppable } from "react-beautiful-dnd";
import { toast } from "react-hot-toast";
import { useFetch } from "../../../helpers/useFetch";
import RoomField from "./RoomField";
import { getMode } from "../../../helpers/helpers";

export default function Room({ room, children, accommodation, setAccommodation, childrenHandlers, accommodationType }) {
  const [roomLoading, setRoomLoading] = useState(false);
  const { mode, theme } = getMode();

  const updateAccommodation = (kid, state) => {
    childrenHandlers.applyWhere(
      (child) => child.id.toString() === kid.toString(),
      (child) => ({ ...child, isAccommodated: state, roomReservation: accommodationType === "reserve" ? state : false })
    );
  };

  const changeReservation = async (child) => {
    let state = child.roomReservation ? child.roomReservation : false;
    setRoomLoading(true);

    useFetch(`/children/${child.id}/accommodate`, "POST", { room_id: room.id, reservation: !state }).then((res) => {
      if (res.status === "ok") {
        toast.success(state ? "Rezervace zrušena. Dítě již přijelo." : "Vytvořena rezervace. Dítě přijede později.");
        setRoomLoading(false);
        childrenHandlers.applyWhere(
          (childLocal) => childLocal.id === child.id,
          (childLocal) => ({ ...childLocal, roomReservation: !state })
        );
      } else {
        toast.error(res.error);
        setRoomLoading(false);
      }
    });
  };

  const changeKid = async (kid, index) => {
    setRoomLoading(true);
    // Duplicate acc state
    let accommodationCopy = [...accommodation];

    // Find floor index
    let floorIndex = accommodationCopy.findIndex((floor) => floor.rooms.find((roomLocal) => roomLocal.id === room.id));

    // Splice array (floor = wanted floor, accommodationCopy = rest of the floors)
    let floor = accommodationCopy.splice(floorIndex, 1);

    // Find room index
    let roomIndex = floor[0].rooms.findIndex((roomLocal) => roomLocal.id === room.id);

    // Is kid set? (not clearing select)
    if (kid) {
      // Is there already kid on this place? Remove him
      if (roomGender && roomGender !== children.filter((child) => String(child.id) === kid)[0].gender) {
        toast.error("Do tohoto pokoje nelze ubytovat dítě jiného pohlaví");
        setTimeout(() => {
          setRoomLoading(false);
        });

        return;
      }
      if (floor[0].rooms[roomIndex].occupation[index]?.id) {
        updateAccommodation(floor[0].rooms[roomIndex].occupation[index].id, false);
        await useFetch(`/children/${floor[0].rooms[roomIndex].occupation[index].id}/accommodate`, "POST", {
          room_id: null,
        });
      }
      useFetch(`/children/${kid}/accommodate`, "POST", {
        room_id: room.id,
        reservation: accommodationType === "reserve" ? true : false,
      }).then((res) => {
        toast.success(accommodationType === "reserve" ? "Místo pro dítě rezervováno." : "Dítě ubytováno");
        setRoomLoading(false);
      });
      // Accoommodate kid
      floor[0].rooms[roomIndex].occupation[index] = children.find((child) => String(child.id) === kid);
      updateAccommodation(kid, true);
    } else {
      // Kid is not set, clear select and unaccommodate kid
      let kid = floor[0].rooms[roomIndex].occupation[index]?.id;
      useFetch(`/children/${kid}/accommodate`, "POST", { room_id: null }).then((res) => {
        toast.success("Dítě odubytováno");
        setRoomLoading(false);
      });
      updateAccommodation(kid, false);
      floor[0].rooms[roomIndex].occupation[index] = [];
    }

    // Update accommodation state and sort floors
    await setAccommodation([...floor, ...accommodationCopy].sort((a, b) => a.id - b.id));
  };

  let roomGender = null;
  const childrenArray = children
    .map((child) => ({
      value: String(child.id),
      label: `${child.firstName} ${child.lastName} (${child.age} ${child?.team})`,
      disabled: child.isAccommodated,
      gender: child.gender,
    }))
    .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1));

  let fields = [];

  if (room.occupation.length > 0) {
    room.occupation.forEach((kid, i) => {
      if (room.occupation[i]?.id) {
        roomGender = kid.gender;
      }
    });
  }

  for (let i = 0; i < room.capacity; i++) {
    let child = children.filter((child) => {
      return child.id === room.occupation[i]?.id;
    })[0];

    fields.push(
      <RoomField
        changeReservation={changeReservation}
        key={`occupation–${i}`}
        index={i}
        changeKid={changeKid}
        roomGender={roomGender}
        room={room}
        child={child}
        childrenArray={childrenArray}
      />
    );
  }

  return (
    <Droppable key={room.id} droppableId={`room-${room.id}`}>
      {(provided, snap) => (
        <Paper
          mih={150}
          ref={provided.innerRef}
          {...provided.droppableProps}
          bg={mode === "dark" ? (snap.isDraggingOver ? "dark.6" : "dark.7") : snap.isDraggingOver ? "gray.1" : "gray.0"}
          p={0}
          className="relative overflow-hidden"
          radius="md"
          withBorder>
          {roomLoading ? (
            <div className="absolute inset-0 left-0 top-0 z-10 flex h-full w-full items-center justify-center">
              <Loader />
            </div>
          ) : (
            <>
              <Group justify="space-between" gap={0}>
                <div
                  className={classNames("w-full py-2 pl-4 pr-3", {
                    "bg-pink-200": roomGender === "Dívka" && room.type === "room" && mode === "dark",
                    "bg-cyan-200": roomGender === "Chlapec" && room.type === "room" && mode === "dark",
                    "bg-pink-500": roomGender === "Dívka" && room.type === "room" && mode === "light",
                    "bg-cyan-500": roomGender === "Chlapec" && room.type === "room" && mode === "light",
                    "bg-yellow-200": room.type === "staff" && mode === "dark",
                    "bg-yellow-400": room.type === "staff" && mode === "light",
                    "bg-red-100": room.type === "sickroom" && mode === "dark",
                    "bg-red-500": room.type === "sickroom" && mode === "light",
                    "bg-dark-300": !roomGender && mode === "dark",
                    "bg-gray-600": !roomGender && mode === "light",
                  })}>
                  <Title order={3}>{room.name}</Title>
                </div>

                <div className="flex w-full flex-col gap-2 px-3 py-3">{fields}</div>
              </Group>
              <span style={{ display: "none" }}>{provided.placeholder}</span>
            </>
          )}
        </Paper>
      )}
    </Droppable>
  );
}
