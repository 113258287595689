import classNames from "classnames";
import useTitle from "../../hooks/useTitle.js";
import {
  Button,
  Collapse,
  Grid,
  Group,
  Loader,
  Paper,
  SegmentedControl,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useState } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import {
  IconArrowDown,
  IconArrowsMinimize,
  IconCheck,
  IconClock,
  IconEye,
  IconEyeOff,
  IconFilter,
  IconMaximize,
  IconSearch,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import ChildrenList from "../../components/accommodation/ChildrenList";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { useFetch } from "../../helpers/useFetch";
import { useFullscreen, useListState } from "@mantine/hooks";
import Floor from "../../components/accommodation/boarding/Floor";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import Page from "../../components/layout/Page";

export async function loader({ params }) {
  return Promise.all([useFetch(`accommodation`), useFetch(`/children/list/accommodation`)]).then(
    ([accommodation, children]) => {
      return { accommodation, children };
    }
  );
}

export default function BoardingPage() {
  const accommodationData = useLoaderData().accommodation.data;
  const childrenData = useLoaderData().children.data;

  const [accommodation, setAccommodation] = useState(accommodationData);
  const [children, childrenHandlers] = useListState(childrenData);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [childrenFiltered, setChildrenFiltered] = useState(() => {
    return childrenData.sort((childA, childB) => {
      return childA.lastName.localeCompare(childB.lastName);
    });
  });
  const [filters, setFilters] = useState({
    gender: "",
    team: "",
  });

  const [search, setSearch] = useState("");
  const [roomsSearch, setRoomsSearch] = useState("");
  const [childListLoading, setChildListLoading] = useState(false);
  const [accommodationType, setAccommodationType] = useState("accommodate");
  const [showSidebar, setShowSidebar] = useState(true);
  const [lastSortType, setLastSortType] = useState("name");
  const { toggle, fullscreen } = useFullscreen();

  const revalidator = useRevalidator();

  const actionButtons = [
    {
      name: fullscreen ? "Zmenšit" : "Zvětšit na celou obrazovku",
      onTrigger: () => {
        toggle();
      },
      icon: fullscreen ? IconArrowsMinimize : IconMaximize,
    },
    {
      name: showSidebar ? "Schovat lištu" : "Zobrazit lištu",
      onTrigger: () => {
        setShowSidebar(!showSidebar);
      },
      icon: showSidebar ? IconEyeOff : IconEye,
      primary: showSidebar ? true : false,
    },
  ];

  const sortChildren = (sortBy) => {
    setLastSortType(sortBy);
    if (sortBy === "name") {
      setChildrenFiltered(() => {
        return childrenFiltered.sort((childA, childB) => {
          return childA.lastName.localeCompare(childB.lastName);
        });
      });
    } else if (sortBy === "age") {
      setChildrenFiltered(() => {
        return childrenFiltered.sort((childA, childB) => {
          return childA.age - childB.age;
        });
      });
    }
  };

  useEffect(() => {
    setChildrenFiltered(children);
    setSearch("");
    setChildListLoading(false);
  }, [children]);

  useTitle(`Přiřazování pokojů`);

  /* useEffect(() => {
    Object.keys(filters).forEach((key) => {
      console.log(
        children.filter((child) => {
          if (filters[key] === "") {
            return true;
          }
          return child[key] === filters[key];
        })
      );
      setChildrenFiltered(
        children.filter((child) => {
          if (filters[key] === "") {
            return true;
          }
          return child[key] === filters[key];
        })
      );
    });
  }, [filters]); */

  const onDragEnd = async (result) => {
    if (
      !result.destination ||
      result.destination.droppableId === result.source.droppableId ||
      result.destination.droppableId === "childList"
    ) {
      return;
    }

    setChildListLoading(true);

    let room = Number(result.destination.droppableId.split("-")[1]);
    let kid = Number(result.draggableId);

    // Duplicate acc state
    let accommodationCopy = [...accommodation];

    // Find floor index
    let floorIndex = accommodationCopy.findIndex((floor) => floor.rooms.find((roomLocal) => roomLocal.id === room));

    // Splice array (floor = wanted floor, accommodationCopy = rest of the floors)
    let floor = accommodationCopy.splice(floorIndex, 1);

    let canAccommodate = false;

    let child = children.find((child) => child.id === kid);

    // Update room kids
    await floor[0].rooms.forEach((roomLocal) => {
      if (roomLocal.id === room) {
        let roomGender = null;
        roomLocal.occupation.forEach((kidLocal) => {
          if (kidLocal.id) {
            roomGender = kidLocal.gender;
          }
        });

        if (child.roomReservation) {
          toast.error("Dítě již má rezervaci pokoje.");
          setChildListLoading(false);
          return;
        }

        if (roomGender && roomGender !== child.gender) {
          toast.error("Do tohoto pokoje nelze ubytovat dítě jiného pohlaví");
          setChildListLoading(false);
          return;
        }

        if (roomLocal.occupation.filter((kidLocal) => kidLocal.id).length >= roomLocal.capacity) {
          toast.error("Tento pokoj je již obsazený.");
          setChildListLoading(false);
          return;
        }
        canAccommodate = true;
        let isAccommodated = false;
        useFetch(`/children/${kid}/accommodate`, "POST", {
          room_id: room,
          reservation: accommodationType === "reserve" ? true : false,
        }).then((res) => {
          if (res.status === "ok") {
            toast.success(accommodationType === "reserve" ? "Místo pro dítě rezervováno." : "Dítě ubytováno");
          }
        });

        roomLocal.occupation.forEach((kidLocal, index) => {
          if (!kidLocal.id && !isAccommodated) {
            isAccommodated = true;
            roomLocal.occupation[index] = child;
          }
        });
      }
    });

    if (canAccommodate) {
      // Update kid
      childrenHandlers.applyWhere(
        (child) => child.id === kid,
        (child) => ({ ...child, isAccommodated: true, roomReservation: accommodationType === "reserve" ? true : false })
      );

      // Update accommodation state and sort floors
      setAccommodation([...floor, ...accommodationCopy].sort((a, b) => a.id - b.id));
    }
  };

  useEffect(() => {
    setChildrenFiltered(
      children.filter((child) => `${child.firstName} ${child.lastName}`.toLowerCase().includes(search.toLowerCase()))
    );
  }, [search]);

  return (
    <Page title="Přiřazení pokojů" actionIcons={actionButtons}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              placeholder="Hledat ubytované dítě..."
              mb="md"
              onChange={(event) => setRoomsSearch(event.currentTarget.value)}
              value={roomsSearch}
              leftSection={<IconSearch stroke={1.5} size={16} />}
            />
            {accommodation.map(
              (floor) =>
                (roomsSearch === "" ||
                  floor.rooms.filter(
                    (room) =>
                      room.occupation.filter((occupation) =>
                        `${occupation?.firstName} ${occupation?.lastName}`
                          ?.toLowerCase()
                          .includes(roomsSearch.toLowerCase())
                      ).length > 0
                  ).length > 0) && (
                  <Floor
                    accommodationType={accommodationType}
                    setAccommodation={setAccommodation}
                    childrenHandlers={childrenHandlers}
                    accommodation={accommodation}
                    children={children}
                    roomsSearch={roomsSearch}
                    key={floor.id}
                    floor={floor}
                  />
                )
            )}
          </Grid.Col>
          {showSidebar && (
            <Grid.Col span={3} className={classNames("hidden lg:block")}>
              <Paper p="md" className="mb-4" withBorder radius="lg">
                {accommodationType === "reserve" ? (
                  <Button
                    leftSection={<IconClock stroke={1.5} size="18" />}
                    variant="filled"
                    color="orange"
                    mb="sm"
                    fullWidth
                    onClick={() => setAccommodationType("accommodate")}>
                    Rezervace
                  </Button>
                ) : (
                  <Button
                    leftSection={<IconCheck stroke={1.5} size="18" />}
                    variant="filled"
                    color="green"
                    fullWidth
                    mb="sm"
                    onClick={() => setAccommodationType("reserve")}>
                    Ubytování
                  </Button>
                )}
                <Group gap="xs" mb="sm">
                  <Button
                    leftSection={<IconFilter stroke={1.5} size="18" />}
                    variant={filtersOpened ? "filled" : "light"}
                    className="hidden grow basis-16"
                    onClick={() => setFiltersOpened(!filtersOpened)}>
                    Filtry
                  </Button>
                  <Button
                    leftSection={
                      lastSortType === "name" || lastSortType === "age" ? (
                        <IconSortDescending stroke={1.5} size="18" />
                      ) : (
                        <IconSortAscending stroke={1.5} size="18" />
                      )
                    }
                    variant="light"
                    className="grow basis-16"
                    onClick={() => (lastSortType === "name" ? sortChildren("age") : sortChildren("name"))}>
                    {lastSortType === "name" ? "Jméno" : lastSortType === "age" ? "Věk" : ""}
                  </Button>
                </Group>
                <Collapse in={filtersOpened}>
                  <div className="mb-4">
                    <Select
                      label="Pohlaví"
                      placeholder="Vyber pohlaví"
                      className="mb-1"
                      onChange={(value) => setFilters({ ...filters, gender: value })}
                      value={filters.gender}
                      data={[
                        {
                          value: "Chlapec",
                          label: "Chlapec",
                        },
                        {
                          value: "Dívka",
                          label: "Dívka",
                        },
                        {
                          value: "",
                          label: "Všechny",
                        },
                      ]}
                    />
                  </div>
                </Collapse>
                <TextInput
                  placeholder="Hledat dítě v seznamu..."
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  value={search}
                  leftSection={<IconSearch stroke={1.5} size={16} />}
                />
                {childListLoading ? (
                  <Stack mt="lg" className="text-center" gap="xs" align="center">
                    <Loader />
                    <Text c="dimmed">Přiřazuju dítě...</Text>
                  </Stack>
                ) : childrenFiltered.length > 0 ? (
                  <ChildrenList children={childrenFiltered} />
                ) : (
                  <Stack align="center" gap={0} mt="lg" className="text-center">
                    <Text className="text-4xl">😿</Text>
                    <Text c="dimmed">Žádné dítě nenalezeno.</Text>
                  </Stack>
                )}
              </Paper>
            </Grid.Col>
          )}
        </Grid>
      </DragDropContext>
    </Page>
  );
}
