import { ActionIcon, Avatar, Group, Menu } from "@mantine/core";
import {
  IconBrush,
  IconCalendarEvent,
  IconChevronDown,
  IconChevronUp,
  IconFileCertificate,
  IconHome,
  IconLogout,
  IconMoodXd,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { openAppearanceModal, openAreaSelectModal, openSessionSelectModal } from "../../helpers/modals";
import { useFetch } from "../../helpers/useFetch";
import { useUser } from "../../providers/UserProvider.jsx";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect, useState } from "react";
import LukPanekJoke from "./LukPanekJoke";
import posthog from "posthog-js";

export default function UserMenu({ session, hideMobileMenu }) {
  const { user } = useUser();
  const navigate = useNavigate();
  const [theme, setTheme] = useState("dark");
  const [opened, setOpened] = useState(false);
  const [jokeOpened, setJokeOpened] = useState(false);
  const [image, setImage] = useState(null);

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = (err) => cb(err);
    img.src = url;
  };

  async function signOut() {
    await toast.promise(useFetch("logout", "POST"), {
      loading: "Odhlašování...",
      success: "Byl jsi úspěšně odhlášen.",
      error: "Nastala chyba při odhlašování",
    });
    posthog.reset();

    localStorage.removeItem("user");
    navigate("/prihlaseni");
  }

  useEffect(() => {
    const theme = localStorage.getItem("theme");
    if (theme === "light") {
      setTheme("light");
    }
    // check if image user.thumbnail is 0 bytes
    if (user.thumbnail !== "") {
      getMeta(user.thumbnail, (err, img) => {
        if (err) {
          setImage(null);
        } else {
          setImage(user.thumbnail);
        }
      });
    }
  }, []);

  return (
    <>
      <LukPanekJoke opened={jokeOpened} setOpened={setJokeOpened} />
      <Menu width={250} withArrow closeOnItemClick={true} shadow="md" opened={opened} onChange={setOpened}>
        <Menu.Target>
          {/*<UnstyledButton>*/}
          <Group gap={0} align="center" className="cursor-pointer">
            <Avatar mr={8} src={image} size={32} radius="sm">
              <IconUser size={24} stroke={1.5} />
            </Avatar>
            <div>
              <h4 className="font-headline leading-0 text-sm">{`${user.firstName} ${user.lastName}`}</h4>
              <p className="text-xs text-dark-800">{user.position}</p>
            </div>
            <ActionIcon ml="xs" size="lg" onClick={() => null} variant="subtle" color="gray">
              {opened ? <IconChevronUp size={24} stroke={1.5} /> : <IconChevronDown size={24} stroke={1.5} />}
            </ActionIcon>
          </Group>
          {/*</UnstyledButton>*/}
        </Menu.Target>

        <Menu.Dropdown mr="md">
          {user.permissions.includes("user.camp-session.change") && (
            <Menu.Item
              onClick={() => {
                openSessionSelectModal(`Změna turnusu`);
                if (hideMobileMenu) hideMobileMenu();
              }}
              leftSection={<IconCalendarEvent stroke={1.5} size={16} />}>
              Změna turnusu
            </Menu.Item>
          )}
          {user.permissions.includes("user.area.change") && (
            <Menu.Item
              onClick={() => {
                openAreaSelectModal(`Změna areálu`);
                if (hideMobileMenu) hideMobileMenu();
              }}
              leftSection={<IconHome stroke={1.5} size={16} />}>
              Změna areálu
            </Menu.Item>
          )}
          <Menu.Item
            leftSection={<IconBrush stroke={1.5} size={16} />}
            onClick={() => {
              openAppearanceModal();
              if (hideMobileMenu) hideMobileMenu();
            }}>
            Úprava vzhledu
          </Menu.Item>
          <Menu.Item
            leftSection={<IconSettings stroke={1.5} size={16} />}
            onClick={() => {
              if (hideMobileMenu) hideMobileMenu();
            }}
            component={Link}
            to={"/nastaveni"}>
            Nastavení uživatele
          </Menu.Item>
          <Menu.Item
            leftSection={<IconFileCertificate stroke={1.5} size={16} />}
            onClick={() => {
              if (hideMobileMenu) hideMobileMenu();
            }}
            component={Link}
            to={"/smlouvy"}>
            Mé smlouvy
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setJokeOpened(true);
              if (hideMobileMenu) hideMobileMenu();
            }}
            leftSection={<IconMoodXd stroke={1.5} size={16} />}>
            Přečíst Lukáš Pánek vtip
          </Menu.Item>

          <Menu.Divider />
          <Menu.Item color="red.6" onClick={() => signOut()} leftSection={<IconLogout stroke={1.5} size={16} />}>
            Odhlásit se
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
