import { Combobox, Group, Input, InputBase, ScrollArea, Text, useCombobox, CloseButton, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { getMode } from "../helpers/helpers";
import { IconCheck } from "@tabler/icons-react";

export default function RichSelect({
  data,
  value,
  onChange,
  label,
  placeholder,
  defaultValue,
  onCreate,
  className,
  searchable,
  creatable,
  getCreateLabel,
  onSearchChange,
  searchValue,
  searching,
  name,
  disabled,
  ...props
}) {
  const [opened, setOpened] = useState(false);
  const { theme } = getMode();

  const selectedOption = data.find((item) => item.value === value);

  const [valueLocal, setValueLocal] = useState(defaultValue || "");
  const [search, setSearch] = useState(selectedOption ? selectedOption.label : "");

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      setOpened(false);
    },
    onDropdownOpen: () => {
      setOpened(true);
    },
  });

  useEffect(() => {
    if (search && onSearchChange) {
      onSearchChange(search);
    }
  }, [search]);

  useEffect(() => {
    if (searchValue) {
      setSearch(searchValue);
    }
  }, [searchValue]);

  const exactOptionMatch = data.some((item) => item.label.toLowerCase() === search.toLowerCase());
  const shouldFilterOptions = data.every((item) => item !== search);

  const filteredOptions =
    shouldFilterOptions && searchable
      ? data.filter((item) => {
          return item.label.toLowerCase().includes(search.toLowerCase());
        })
      : data;

  const options = filteredOptions.map((item) => {
    const Label = label;

    return (
      <Combobox.Option value={item.value} key={item.value} active={item.value === valueLocal} disabled={item.disabled}>
        <Group gap="xs" align="center">
          {item.value === valueLocal && (
            <Text c={theme.primaryColor} className="flex items-center">
              <IconCheck stroke={1.5} size={18} />
            </Text>
          )}
          <Label {...item} />
        </Group>
      </Combobox.Option>
    );
  });

  useEffect(() => {
    if (!value) return;
    const label = data.find((item) => item.value === value)?.label;
    if (value && label && label !== search) {
      setSearch(label);
    }
  }, [value]);

  useEffect(() => {}, [valueLocal]);

  return (
    <Combobox
      store={combobox}
      withinPortal={true}
      className={className}
      onOptionSubmit={(val) => {
        if (val === "$create") {
          const item = onCreate(search);
          setValueLocal(item.value);
        } else {
          setValueLocal(val);
          onChange(val);
          if (searchable) {
            setSearch(data.find((item) => item.value === val).label);
          }
        }

        combobox.closeDropdown();
      }}>
      <Combobox.Target>
        {searchable ? (
          <InputBase
            rightSection={
              valueLocal || search ? (
                <CloseButton
                  size="sm"
                  onChange={setSearch}
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => {
                    setValueLocal(null);
                    onChange(null);
                    setSearch("");
                    combobox.closeDropdown();
                  }}
                  className="!z-10"
                  aria-label="Clear value"
                />
              ) : (
                <Combobox.Chevron />
              )
            }
            disabled={disabled}
            label={name}
            onChange={(event) => {
              combobox.openDropdown();
              combobox.updateSelectedOptionIndex();
              setSearch(event.currentTarget.value);
            }}
            onBlur={() => {
              combobox.closeDropdown();
              setSearch(selectedOption ? selectedOption.label : "");
            }}
            onClick={() => combobox.openDropdown()}
            value={search}
            className="relative overflow-hidden text-nowrap"
            placeholder={placeholder}
          />
        ) : (
          <InputBase
            component="button"
            type="button"
            pointer
            disabled={disabled}
            label={name}
            rightSection={
              value || value !== "undefined" ? (
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => event.preventDefault()}
                  onClick={() => {
                    setValueLocal(null);
                    onChange(null);
                  }}
                  aria-label="Clear value"
                />
              ) : (
                <Combobox.Chevron />
              )
            }
            onClick={() => combobox.toggleDropdown()}
            className="relative overflow-hidden text-nowrap">
            {selectedOption ? (
              <Text span className="block w-full overflow-hidden">
                {selectedOption.label}
              </Text>
            ) : (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}
          </InputBase>
        )}
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options>
          <ScrollArea.Autosize mah={200} type="scroll">
            {opened && (
              <>
                {searching ? (
                  <Loader className="flex items-center justify-center" w={"100%"} py={"md"} size="sm" />
                ) : (
                  <>
                    {data.length > 0 ? (
                      options
                    ) : (
                      <Text py={2} ta={"center"}>
                        😢 Nic zde není
                      </Text>
                    )}
                    {!exactOptionMatch && search.trim().length > 0 && creatable && (
                      <Combobox.Option value="$create">
                        + {getCreateLabel ? getCreateLabel(search) : search}
                      </Combobox.Option>
                    )}
                  </>
                )}
              </>
            )}
          </ScrollArea.Autosize>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
