import { Group, Text, Tooltip, useMantineTheme } from "@mantine/core";
import { IconBus, IconClock, IconWalk } from "@tabler/icons-react";
import classNames from "classnames";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { getMode, parseFullName } from "../../helpers/helpers";
import styles from "./ChildrenList.module.scss";

export default function ChildrenList({ children }) {
  function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
      return style;
    }
    return {
      ...style,
      /* transitionDuration: `0.001s`, */
    };
  }

  const { mode, theme } = getMode();

  let childrenLocal = children.filter((child) => !child.isAccommodated || child.roomReservation);

  return (
    <Droppable droppableId="childList">
      {(provided, snap) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className="mt-2">
          {childrenLocal.map((child, i) => (
            <Draggable key={child.id} draggableId={`${child.id}`} index={i}>
              {(provided, snap) => (
                <Group
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getStyle(provided.draggableProps.style, snap)}
                  align="center"
                  wrap="nowrap"
                  p={4}
                  mb={4}
                  className={classNames(styles.Child, "whitespace-nowrap")}
                  justify="space-between"
                  key={child.id}>
                  <Text lineClamp={1} className={classNames("flex items-center", styles.nameDisplay)}>
                    {child.arriveByBus && (
                      <IconBus
                        color={child.roomReservation ? theme.colors.orange[5] : mode === "dark" ? "white" : "black"}
                        className="mr-2 shrink-0"
                        stroke={1.5}
                        size={20}
                      />
                    )}
                    {!child.arriveByBus && (
                      <IconWalk
                        color={child.roomReservation ? theme.colors.orange[5] : mode === "dark" ? "white" : "black"}
                        className="mr-2 shrink-0"
                        stroke={1.5}
                        size={20}
                      />
                    )}
                    <Text c={child.gender === "Dívka" ? "pink.6" : undefined} span>
                      {child.firstName} {child.lastName}
                    </Text>
                  </Text>
                  <Text c="dimmed">
                    {child.age} {child.team && child.team}
                  </Text>
                </Group>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}
