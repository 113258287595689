import {
  IconApps,
  IconBed,
  IconBrandDiscord,
  IconBrandMcdonalds,
  IconBuildingWarehouse,
  IconCamera,
  IconDashboard,
  IconDeviceDesktop,
  IconDoorEnter,
  IconFileDownload,
  IconFlag,
  IconListSearch,
  IconMoodKid,
  IconStethoscope,
  IconUsers,
} from "@tabler/icons-react";

export default [
  {
    id: "",
    url: "/",
    name: "Přehled",
    icon: IconDashboard,
    enableWithoutSession: true,
  },
  {
    id: "deti",
    name: "Děti",
    icon: IconMoodKid,
    permissions: ["module.children"],
    children: [
      {
        id: "prehled",
        name: "Přehled dětí",
        url: "/deti/prehled",
        permissions: ["children.list"],
      },
      {
        id: "odjezdy",
        name: "Předčasné odjezdy",
        url: "/deti/odjezdy",
        permissions: ["children.early-leavings.list"],
      },
      {
        id: "dietari",
        name: "Dietáři",
        url: "/deti/dietari",
        /* permissions: ["money"], */
      },
      {
        id: "penize/kauce",
        name: "Kauce",
        url: "/deti/penize/kauce",
        permissions: ["children.money.deposit.list"],
      },
      {
        id: "penize/kapesne",
        name: "Kapesné",
        url: "/deti/penize/kapesne",
        permissions: ["children.money.pocket.list"],
      },
      {
        id: "dokumenty",
        name: "Přehled dokumentů",
        url: "/deti/dokumenty",
        permissions: ["children.documents.list"],
      },
    ],
  },
  {
    id: "oddily",
    name: "Oddíly",
    icon: IconFlag,
    permissions: ["module.teams"],
    children: [
      {
        id: "prehled",
        name: "Přehled oddílů",
        url: "/oddily/prehled",
        permissions: ["teams.list"],
      },
      {
        id: "muj",
        name: "Můj oddíl",
        url: "/oddily/muj",
        permissions: ["teams.mine"],
      },
      {
        id: "vytvoreni",
        name: "Vytvoření oddílů",
        url: "/oddily/vytvoreni",
        permissions: ["teams.create"],
      },
      {
        id: "pozadavky",
        name: "Požadavky",
        url: "/oddily/pozadavky",
        permissions: ["teams.tickets.list"],
      },
      {
        id: "moje-pozadavky",
        name: "Moje požadavky",
        url: "/oddily/moje-pozadavky",
        permissions: ["teams.tickets.mine"],
      },
    ],
  },
  {
    id: "ubytovani",
    name: "Ubytování",
    icon: IconBed,
    permissions: ["module.accommodation"],
    children: [
      {
        id: "prehled",
        name: "Přehled ubytování",
        url: "/ubytovani/prehled",
        permissions: ["accommodation.list"],
      },
      {
        id: "pokoje",
        name: "Správa pokojů",
        url: "/ubytovani/pokoje",
        permissions: ["accommodation.rooms.list"],
      },
      {
        id: "prirazeni",
        name: "Přiřazení pokojů",
        url: "/ubytovani/prirazeni",
        permissions: ["accommodation.rooms.assign"],
      },
      {
        id: "skody",
        name: "Přehled škod",
        url: "/ubytovani/skody",
        permissions: ["accommodation.rooms.issues.list"],
      },
      {
        id: "bodovani",
        name: "Bodování pokojů",
        url: "/ubytovani/bodovani",
        permissions: ["accommodation.rooms.points.list"],
      },
    ],
  },
  {
    id: "zdravotnik",
    name: "Zdravotník",
    icon: IconStethoscope,
    permissions: ["module.medical"],
    children: [
      {
        id: "prehled",
        name: "Přehled dětí",
        url: "/zdravotnik/prehled",
        permissions: ["medical.list"],
      },

      {
        id: "prehled-zdravi",
        name: "Přehled zdraví",
        url: "/zdravotnik/prehled-zdravi",
        permissions: ["medical.overview"],
      },
      {
        id: "osetreni",
        name: "Seznam ošetření",
        url: "/zdravotnik/osetreni",
        permissions: ["medical.records.list"],
      },
      {
        id: "dnesni-kontroly",
        name: "Dnešní kontroly",
        url: "/zdravotnik/dnesni-kontroly",
        permissions: ["medical.records.regular-check.list"],
      },
      {
        id: "pravidelne-leky",
        name: "Pravidelné léky",
        url: "/zdravotnik/pravidelne-leky",
        permissions: ["medical.medications.list"],
      },
    ],
  },
  {
    id: "fotograf",
    name: "Fotograf",
    icon: IconCamera,
    permissions: ["module.photographer"],
    children: [
      {
        id: "flashky",
        name: "Seznam flashek",
        url: "/fotograf/flashky",
        permissions: ["photographer.flash-disks.list"],
      },
    ],
  },
  {
    id: "akreditace",
    name: "Akreditace",
    icon: IconDoorEnter,
    permissions: ["module.accreditation"],
    children: [
      {
        id: "prehled",
        name: "Přehled akreditace",
        url: "/akreditace/prehled",
        permissions: ["accreditation.list"],
      },
      {
        id: "prehled-odjezdu",
        name: "Přehled odjezdů",
        url: "/akreditace/prehled-odjezdu",
        permissions: ["accreditation.suburban-leaings.list"],
      },
      {
        id: "merch",
        name: "Merch",
        url: "/akreditace/merch",
        permissions: ["accreditation.merch"],
      },
      {
        id: "zdravotnik",
        name: "Zdravotník",
        url: "/akreditace/zdravotnik",
        permissions: ["accreditation.medical"],
      },
      {
        id: "rady-a-kauce",
        name: "Řády a kauce",
        url: "/akreditace/rady-a-kauce",
        permissions: ["accreditation.documents"],
      },
      {
        id: "kapesne",
        name: "Kapesné",
        url: "/akreditace/kapesne",
        permissions: ["accreditation.pocket-money"],
      },
      {
        id: "../ubytovani/prirazeni",
        name: "Ubytování",
        url: "/ubytovani/prirazeni",
        permissions: ["accreditation.accommodation"],
      },
      {
        id: "delegat-prijezd",
        name: "Delegát – příjezd",
        url: "/akreditace/delegat-prijezd",
        permissions: ["accreditation.delegate.arrival"],
      },
      {
        id: "delegat-odjezd",
        name: "Delegát – odjezd",
        url: "/akreditace/delegat-odjezd",
        permissions: ["accreditation.delegate.departure"],
      },
      {
        id: "odjezdy",
        name: "Odjezdy",
        url: "/akreditace/odjezdy",
        permissions: ["accreditation.departure"],
      },
    ],
  },
  {
    id: "personal",
    name: "Personál",
    icon: IconUsers,
    permissions: ["module.staff"],
    children: [
      {
        id: "prehled",
        name: "Přehled personálu",
        url: "/personal/prehled",
        permissions: ["staff.list"],
      },
      {
        id: "prava",
        name: "Správa práv",
        url: "/personal/prava",
        permissions: ["staff.privileges.edit"],
      },
      {
        id: "smlouvy",
        name: "Smlouvy",
        url: "/personal/smlouvy",
        permissions: ["staff.contracts.list"],
      },
      {
        id: "losovani",
        name: "Losování",
        url: "/personal/losovani",
        permissions: ["staff.lottery"],
      },
      /* {
        id: "predavaky",
        name: "Předáváky",
        url: "/personal/predavaky",
        permissions: ["staff.roles"],
      }, */
    ],
  },
  {
    id: "dokumenty",
    name: "Dokumenty",
    icon: IconFileDownload,
    permissions: ["module.documents"],
  },
  {
    id: "pc-program",
    name: "Počítačový program",
    icon: IconDeviceDesktop,
    permissions: ["module.pc"],
    children: [
      {
        id: "deti",
        name: "Přehled dětí",
        url: "/pc-program/deti",
        permissions: ["pc.list"],
      },
      {
        id: "prehled",
        name: "Přehled počítačů",
        url: "/pc-program/prehled",
        permissions: ["pc.pc.list"],
      },
      {
        id: "mistnosti",
        name: "Správa místností",
        url: "/pc-program/mistnosti",
        permissions: ["pc.pc.rooms.list"],
      },
      {
        id: "herni-skupiny",
        name: "Herní skupiny",
        url: "/pc-program/herni-skupiny",
        permissions: ["pc-game-groups.list"],
      },
      {
        id: "dotazniky",
        name: "Dotazníky",
        url: "/pc-program/dotazniky",
        permissions: ["pc.survey"],
      },
      {
        id: "mc-admin-whitelist",
        name: "Správa Minecraft adminů",
        url: "/pc-program/mc-admin-whitelist",
        permissions: ["pc.minecraft"],
      },
      {
        id: "questy-plneni",
        name: "Plnění questů",
        url: "/pc-program/questy-plneni",
        permissions: ["pc.quests.complete"],
      },
      {
        id: "questy-editace",
        name: "Editace questů",
        url: "/pc-program/questy-editace",
        permissions: ["pc.quests.list"],
      },
    ],
  },
  /* {
    id: "navody",
    name: "Návody",
    icon: IconBook2,
    enableWithoutSession: true,
  }, */
  {
    id: "discord",
    name: "Discord",
    icon: IconBrandDiscord,
    permissions: ["module.discord"],
    children: [
      {
        id: "zpravy",
        name: "Plánování zpráv",
        url: "/discord/zpravy",
        permissions: ["discord.scheduled-messages.list"],
      },
    ],
  },
  {
    id: "bufet",
    name: "Bufet",
    icon: IconBrandMcdonalds,
    permissions: ["module.buffet"],
    children: [
      {
        id: "kasa",
        name: "Kasa",
        url: "/bufet/kasa",
        permissions: ["buffet.pos"],
      },
      {
        id: "sklad",
        name: "Sklad",
        url: "/bufet/sklad",
        permissions: ["buffet.depot"],
      },
      {
        id: "prehled",
        name: "Přehled",
        url: "/bufet/prehled",
        permissions: ["buffet.overview"],
      },
    ],
  },
  {
    id: "sklad",
    name: "Sklad",
    icon: IconBuildingWarehouse,
    permissions: ["module.depot"],
    children: [
      {
        id: "prehled",
        name: "Přehled",
        url: "/sklad/prehled",
        permissions: ["depot.overview"],
      },
      {
        id: "logy",
        name: "Logy",
        url: "/sklad/logy",
        permissions: ["depot.logs"],
      },
      {
        id: "predavaci-protokoly",
        name: "Předávací protokoly",
        url: "/sklad/predavaci-protokoly",
        permissions: ["depot.handover-protocols.list"],
      },
    ],
  },
  {
    id: "logy",
    name: "Audit logy",
    icon: IconListSearch,
    permissions: ["module.audit"],
  },
  {
    id: "test",
    name: "Test",
    icon: IconApps,
    permissions: ["fe.founders"],
  },
];
