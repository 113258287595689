import { Collapse, Group, Paper } from "@mantine/core";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";
import { useEffect } from "react";
import { useState } from "react";
import Room from "./Room";

export default function Floor({
  floor,
  children,
  accommodation,
  setAccommodation,
  childrenHandlers,
  roomsSearch,
  accommodationType,
}) {
  const [opened, setOpened] = useState(false);
  const [rooms, setRooms] = useState((floor.rooms = floor.rooms.filter((room) => room.type === "room")));

  useEffect(() => {
    if (roomsSearch !== "") {
      setRooms(
        floor.rooms.filter(
          (room) =>
            room.occupation.filter((kid) =>
              `${kid.firstName} ${kid.lastName}`?.toLowerCase().includes(roomsSearch.toLowerCase())
            ).length > 0
        )
      );
      if (rooms.length > 0) {
        setOpened(true);
      } else {
        setOpened(false);
      }
    } else {
      setRooms(floor.rooms.filter((room) => room.type === "room"));
      setOpened(false);
    }
  }, [roomsSearch]);

  return (
    <>
      <Paper p="sm" className="mb-4 cursor-pointer" withBorder radius="md" onClick={() => setOpened(!opened)}>
        <Group justify="space-between">
          <h2>{floor.name}</h2>
          {opened ? <IconChevronUp stroke={1.5} /> : <IconChevronDown stroke={1.5} />}
        </Group>
      </Paper>
      <Collapse in={opened}>
        <div className="mb-5 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-4">
          {rooms.map((room) => (
            <Room
              accommodationType={accommodationType}
              accommodation={accommodation}
              key={room.id}
              childrenHandlers={childrenHandlers}
              setAccommodation={setAccommodation}
              children={children}
              room={room}
            />
          ))}
        </div>
      </Collapse>
    </>
  );
}
