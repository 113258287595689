import { Transition, Group, NavLink, ScrollArea } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import UserMenu from "./UserMenu.jsx";
import { useUser } from "../../providers/UserProvider.jsx";
import menuRoutes from "../../helpers/menuRoutes.js";
import logoText from "../../assets/logo-text.svg";
import classNames from "classnames";
import styles from "./NavigationLeft.module.scss";
import { useEffect, useState } from "react";
import LogoSymbol from "../LogoSymbol.jsx";
import { getMode } from "../../helpers/helpers.js";

export default function NavigationLeft({
  className,
  setShowMobileMenu,
  session,
  campSessions,
  minifyMenu,
  setMinifyMenu,
}) {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname.replace(/^\/|\/$/g, ""));
  useEffect(() => {
    setCurrentPath(location.pathname.replace(/^\/|\/$/g, ""));
  }, [location]);
  const { user } = useUser();
  const [minifyMenuLocal, setMinifyMenuLocal] = useState(null);

  let hideMobileMenu = () => {
    if (setShowMobileMenu) {
      setShowMobileMenu(false);
    }
  };

  useEffect(() => {
    setMinifyMenuLocal(minifyMenu);
  }, [minifyMenu]);

  let changeMinify = (variant) => {
    if (minifyMenu) {
      setMinifyMenuLocal(variant);
    }
  };

  const { mode, theme } = getMode();

  return (
    <ScrollArea
      onMouseOver={() => changeMinify(false)}
      onMouseLeave={() => changeMinify(true)}
      className={classNames(className, { [`${styles.minified}`]: minifyMenuLocal }, "[&>div>div]:block")}>
      <Link to="/">
        <Group wrap="nowrap" gap="sm" className="hidden h-16 px-4 md:flex" align="center">
          <LogoSymbol height={32} />
          <img
            src={logoText}
            className={classNames(styles.logoText, { [`${styles.logoTextMinify}`]: minifyMenuLocal })}
            style={{
              filter: mode === "light" && "invert(1)",
            }}
            height={32}
            alt="Logo"
          />
          {import.meta.env.DEV && (
            <p className="ml-1 rounded-md bg-red-100 px-[8px] py-[4px] text-xs font-bold text-[#fff]">DEV</p>
          )}
        </Group>
      </Link>

      <div className="relative mb-[70px] overflow-x-hidden">
        {menuRoutes
          .filter((route) => {
            return !user.currentCampSession ? route.enableWithoutSession : true;
          })
          .map((route) => {
            let children;
            if (route.children) {
              children = route.children.map((child) => {
                if (child.permissions) {
                  if (!child.permissions || !user.permissions?.some((p) => child.permissions?.indexOf(p) >= 0))
                    return null;
                }

                if (child.id === 'prehled-odjezdu') {
                  if (!user.currentCampSession?.isSuburban) {
                    return null;
                  }
                }

                const path = `${route.id}/${child.id}`;

                return (
                  <Link
                    to={`/${path}`}
                    onClick={() => {
                      hideMobileMenu();
                      setCurrentPath(path);
                    }}
                    className="block w-full"
                    key={child.id}>
                    <div className="w-full overflow-x-hidden pl-8">
                      <NavLink
                        className={classNames("min-w-[15rem] border-l-2 border-solid pl-6", {
                          "border-l-dark-400": mode === "dark",
                          "border-l-gray-600": mode === "light",
                        })}
                        active={currentPath === path}
                        px={32}
                        label={child.name}
                        component="span"
                      />
                    </div>
                  </Link>
                );
              });
            }

            if (route.permissions) {
              if (!user?.permissions?.some((p) => route.permissions?.indexOf(p) >= 0)) return <></>;
            }

            const Icon = route.icon;
            return (
              <NavLink
                py="md"
                px="1.25rem"
                component={!route.children ? Link : "p"}
                onClick={
                  !route.children
                    ? () => {
                        hideMobileMenu();
                        setCurrentPath(route.id);
                      }
                    : null
                }
                to={`/${route.id}`}
                key={route.id}
                label={route.name}
                variant="filled"
                active={currentPath.split("/")[0] === route.id}
                defaultOpened={currentPath.split("/")[0] === route.id}
                leftSection={<Icon size={24} stroke={1.5} />}
                childrenOffset={0}
                className="whitespace-nowrap">
                {children && (
                  <Transition mounted={!minifyMenuLocal} transition="scale-y" duration={250} timingFunction="ease">
                    {(styles) => <div style={styles}>{children}</div>}
                  </Transition>
                )}
              </NavLink>
            );
          })}
      </div>
      {setShowMobileMenu && (
        <Group
          className={classNames("fixed bottom-0 w-full justify-between px-5 py-4", {
            "bg-dark-50": mode === "dark",
            "bg-gray-900": mode === "light",
          })}>
          <Group>
            <UserMenu session={session} hideMobileMenu={hideMobileMenu} campSessions={campSessions} />
          </Group>
          <Group gap={8}>
            {/* <ActionIcon size="lg" variant="subtle">
              <Indicator>
                <IconBell size={24} stroke={1.5} />
              </Indicator>
            </ActionIcon> */}
            {/* <ActionIcon size="lg" variant="subtle">
              <IconSettings size={24} stroke={1.5} />
            </ActionIcon> */}
          </Group>
        </Group>
      )}
    </ScrollArea>
  );
}
