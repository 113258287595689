import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import styles from "../pages.module.scss";
import classNames from "classnames";
import { UserProvider } from "../providers/UserProvider.jsx";
import { useEffect, useState } from "react";
import NavigationTop from "../components/layout/NavigationTop.jsx";
import NavigationLeft from "../components/layout/NavigationLeft.jsx";
import ToastsProvider from "../providers/ToastsProvider.jsx";
import { Loader, Text } from "@mantine/core";
import TicketModal from "../components/modals/TicketModal.jsx";
import HistoricalNoteModal from "../components/modals/HistoricalNoteModal.jsx";
import EarlyLeaveModal from "../components/modals/EarlyLeaveModal.jsx";
import DiscordScheduledMessageModal from "../components/modals/DiscordScheduledMessageModal.jsx";
import { ModalsProvider } from "@mantine/modals";
/* import SpotlightProvider from "../providers/SpotlightProvider.jsx"; */
import SessionSelectModal from "../components/modals/SessionSelectModal.jsx";
import TeamEditModal from "../components/modals/TeamEditModal.jsx";
import { useFullscreen, useLocalStorage, useNetwork } from "@mantine/hooks";
import ChildTransferModal from "../components/modals/ChildTransferModal.jsx";
import RoomEditModal from "../components/modals/RoomEditModal.jsx";
import RoomIssueModal from "../components/modals/RoomIssueModal.jsx";
import RoomRateModal from "../components/modals/RoomRateModal.jsx";
import NewMedicationModal from "../components/modals/NewMedicationModal";
import MedicationTakeModal from "../components/modals/MedicationTakeModal";
import MedicationEditModal from "../components/modals/MedicationEditModal";
import FileSelectModal from "../components/modals/FileSelectModal";
import MoneyRemoveModal from "../components/modals/MoneyRemoveModal";
import AppearanceModal from "../components/modals/AppearanceModal";
import { getMode } from "../helpers/helpers";
import { MoneyChangeModal } from "../components/modals/MoneyChangeModal";
import TransportBuyModal from "../components/modals/TransportBuyModal";
import UpdateSignatureModal from "../components/users/UpdateSignatureModal.jsx";
import AreaSelectModal from "../components/modals/AreaSelectModal";
import ReloadPrompt from "../components/ReloadPrompt";
import CreateRoomModal from "../components/modals/CreateRoomModal";
import FormStartModal from "../components/modals/FormStartModal";
import NewPcMinecraftAdminWhitelistModal from "../components/modals/NewPcMinecraftAdminWhitelistModal.jsx";
import McNicknameModal from "../components/modals/McNicknameModal.jsx";
import PcNoteModal from "../components/modals/PcNoteModal";
import LagRadar from "react-lag-radar";
import { useFps } from "react-fps";
import SpotlightProvider from "../providers/SpotlightProvider.jsx";
import { NewArticleModal } from "../components/modals/NewArticleModal.jsx";
import { EditArticleModal } from "../components/modals/EditArticleModal.jsx";
import { MoveArticleModal } from "../components/modals/MoveArticleModal.jsx";
import { ArticleCategoriesModal } from "../components/modals/ArticleCategoriesModal.jsx";
import { DepotsManageModal } from "../components/modals/DepotsManageModal.jsx";
import FilePhotoModal from "../components/modals/FilePhotoModal";
import PcChildAccountModal from "../components/modals/PcChildAccountModal.jsx";
import { DepotHandoverCreateModal } from "../components/modals/DepotHandoverCreateModal.jsx";
import GameGroupModal from "../components/modals/GameGroupModal.jsx";
import AddPcChildAccountModal from "../components/modals/AddPcChildAccountModal.jsx";
import PcEditQuestModal from "../components/modals/PcEditQuestModal.jsx";
import PcNewQuestModal from "../components/modals/PcNewQuestModal.jsx";
import PcDeleteQuestModal from "../components/modals/PcDeleteQuestModal.jsx";
import PcFillQuestModal from "../components/modals/PcFillQuestModal.jsx";
import PcCopyQuestsModal from "../components/modals/PcCopyQuestsModal.jsx";

export function Layout() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [minifyMenu, setMinifyMenu] = useLocalStorage({
    key: "minify-menu",
    defaultValue: false,
  });

  const [requestsHistory, setRequestsHistory] = useState([]);

  const [debug, setDebug] = useLocalStorage({
    key: "debug",
    defaultValue: false,
  });
  const navigation = useNavigation();
  const network = useNetwork();
  const [isDragging, setIsDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [dragDistance, setDragDistance] = useState(0);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const fps = useFps();

  useEffect(() => {
    const userItem = localStorage.getItem("user");

    if (!userItem) {
      navigate("/prihlaseni");
    }

    const userLocal = JSON.parse(userItem);

    setUser(userLocal);
  }, []);

  useEffect(() => {
    if (debug) {
      console.log(
        "%c>_%cpanel%c Debug režim",
        "color: #fff; padding: 5px; background: rgb(76, 110, 245); font-weight: bold;",
        "color: #fff; padding: 5px; background: #0E0F15;",
        "color: #fff; background: transparent;"
      );
    }
  }, [debug]);

  const handlePointerDown = (e) => {
    setIsDragging(true);
    if (typeof e["targetTouches"] !== "undefined") {
      setInitialY(e["targetTouches"][0].clientY);
    } else {
      setInitialY(e.clientY);
    }
  };

  const handlePointerUp = (e) => {
    setIsDragging(false);
    if (shouldRefresh) {
      setIsRefreshing(true);
      setDragDistance(100);
      setTimeout(() => {
        setIsRefreshing(false);
        window.location.reload();
      }, 1000);
    } else {
      setDragDistance(0);
    }
  };

  const handlePointerMove = (e) => {
    if (isDragging) {
      const distance =
        typeof e["targetTouches"] !== "undefined" ? e["targetTouches"][0].clientY - initialY : e.clientY - initialY;
      if (distance / 2 > 75) {
        setDragDistance(75 + (distance - 75) / 10);
      } else {
        setDragDistance(distance / 2);
      }
      if (dragDistance >= 75) {
        setShouldRefresh(true);
      } else {
        setShouldRefresh(false);
      }
    }
  };

  const campSessions = [];

  const { mode, theme } = getMode();

  const { fullscreen } = useFullscreen();

  return (
    <>
      {user && (
        <UserProvider user={user}>
          <ModalsProvider
            modals={{
              ticket: TicketModal,
              historicalNote: HistoricalNoteModal,
              earlyLeave: EarlyLeaveModal,
              changeSession: SessionSelectModal,
              changeArea: AreaSelectModal,
              teamEdit: TeamEditModal,
              childTransfer: ChildTransferModal,
              roomEdit: RoomEditModal,
              roomIssue: RoomIssueModal,
              roomRate: RoomRateModal,
              newMedication: NewMedicationModal,
              medicationTake: MedicationTakeModal,
              medicationEdit: MedicationEditModal,
              fileSelect: FileSelectModal,
              moneyRemove: MoneyRemoveModal,
              moneyChange: MoneyChangeModal,
              appearance: AppearanceModal,
              transportBuy: TransportBuyModal,
              userSignatureUpdate: UpdateSignatureModal,
              createRoom: CreateRoomModal,
              gameGroup: GameGroupModal,
              newPcMinecraftAdminWhitelist: NewPcMinecraftAdminWhitelistModal,
              formStart: FormStartModal,
              mcNickname: McNicknameModal,
              pcNote: PcNoteModal,
              newArticle: NewArticleModal,
              editArticle: EditArticleModal,
              moveArticle: MoveArticleModal,
              articleCategories: ArticleCategoriesModal,
              depotsManage: DepotsManageModal,
              filePhoto: FilePhotoModal,
              pcChildAccount: PcChildAccountModal,
              addPcChildAccount: AddPcChildAccountModal,
              depotHandoverCreate: DepotHandoverCreateModal,
              pcEditQuest: PcEditQuestModal,
              pcNewQuest: PcNewQuestModal,
              pcDeleteQuest: PcDeleteQuestModal,
              pcFillQuest: PcFillQuestModal,
              pcCopyLastWeekQuests: PcCopyQuestsModal,
              discordScheduledMessageModal: DiscordScheduledMessageModal,
            }}
            modalProps={{
              size: 600,
              padding: 24,
              radius: "lg",
              scrollAreaComponent: "div",
            }}>
            <SpotlightProvider>
              {/* {import.meta.env.DEV && (
                <Text
                  bg="red"
                  color="white"
                  size="sm"
                  className="absolute z-[15] w-full py-1 md:relative"
                  align="center"
                >
                  Pracuješ ve vývojovém prostředí.
                </Text>
              )} */}

              {!network.online && (
                <Text
                  bg="red"
                  color="white"
                  size="sm"
                  className="absolute z-[15] w-full py-1 md:relative"
                  align="center">
                  Jsi offline! Připoj se k internetu.
                </Text>
              )}

              <div className={classNames(styles.layout, "overflow-hidden")}>
                <NavigationTop campSessions={campSessions} setMinifyMenu={setMinifyMenu} minifyMenu={minifyMenu} />
                <NavigationLeft
                  className={classNames("flex-col border-0 border-r-2 border-solid", styles.NavigationLeft, {
                    "border-dark-400 bg-dark-50": mode === "dark",
                    "border-gray-600 bg-gray-900": mode === "light",
                    hidden: fullscreen,
                    "hidden md:flex": !fullscreen,
                  })}
                  minifyMenu={minifyMenu}
                  setMinifyMenu={setMinifyMenu}
                />
                {/* <PullProvider className={styles.Content}> */}
                {navigation.state === "loading" ? (
                  <div
                    className={classNames("flex h-full w-full flex-col items-center justify-center", styles.Content)}>
                    <Loader />
                  </div>
                ) : (
                  // <div
                  //   onTouchStart={handlePointerDown}
                  //   onTouchEnd={handlePointerUp}
                  //   onTouchMove={handlePointerMove}
                  //   className={classNames("pb-8", styles.Content)}
                  // >
                  //   <div
                  //     className={classNames(
                  //       "flex w-full items-center justify-center overflow-y-hidden bg-dark-100 text-white-900 shadow-xl"
                  //     )}
                  //     style={{
                  //       height: dragDistance + "px",
                  //     }}
                  //   >
                  //     <IconRefresh
                  //       size={24}
                  //       stroke={1.5}
                  //       className={classNames("transition-colors duration-150", {
                  //         "animate-[spin_500ms_linear_infinite]": isRefreshing,
                  //       })}
                  //       style={{
                  //         transform: `rotate(${dragDistance * 2}deg)`,
                  //       }}
                  //       color={shouldRefresh ? theme.colors[theme.primaryColor][6] : "white"}
                  //     />
                  //   </div>

                  <>
                    <ReloadPrompt />
                    <div className={classNames("pb-8", styles.Content)}>
                      <Outlet />
                    </div>
                  </>
                  // </div>
                )}
                {/* </PullProvider> */}
              </div>

              <ToastsProvider />
            </SpotlightProvider>
          </ModalsProvider>
          {debug && (
            <div className="pointer-events-none absolute bottom-12 right-12 z-[1000] flex gap-4">
              {/* <div className="flex w-[500px] flex-col  gap-2 bg-dark-50/75 px-4 py-3">
                {requestsHistory.length > 0 &&
                  requestsHistory.slice(-6).map((request) => (
                    <Text size="sm" className="flex items-center justify-between gap-2">
                      <Text span>
                        <Text span color="green" fw="bold">
                          {request.method}
                        </Text>{" "}
                        {request.endpoint}
                      </Text>
                      <Text span className="flex items-center gap-1">
                        <IconClock size={12} /> {request.time} ms
                      </Text>
                    </Text>
                  ))}
              </div> */}
              <div className="pointer-events-none bg-dark-50/75 px-4 py-3">
                <Text size="sm" color="gray" className="mb-2">
                  <Text span color={network.online ? "green" : "red"}>
                    {network.online ? "Online" : "Offline"}
                  </Text>{" "}
                  {fps.currentFps} FPS
                </Text>
                <LagRadar size={150} />
              </div>
            </div>
          )}
        </UserProvider>
      )}
    </>
  );
}
