import { ActionIcon, Button, Card, Group, Image, Text, Tooltip } from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import { useFetch } from "../../helpers/useFetch.js";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { IconArrowBackUp, IconCheck, IconTool } from "@tabler/icons-react";
import { useRevalidator } from "react-router-dom";
import { openRoomIssueModal } from "../../helpers/modals.js";
import Gallery from "../Gallery.jsx";
import Drawer from "../drawer/Drawer.jsx";
import { useUser } from "../../providers/UserProvider.jsx";

export default function IssuesDrawer({ opened, onClose, room }) {
  const [toggleLoading, setToggleLoading] = useState(false);
  const revalidator = useRevalidator();
  const { user } = useUser();

  let toggleStatus = async (issue) => {
    setToggleLoading(true);
    let type = issue.resolvedAt === null ? "resolve" : "open";
    const res = await useFetch(`accommodation/rooms/issues/${issue.id}/${type}`, "PUT");
    if (res.status === "ok") {
      toast.success("Škoda byla označena jako " + (type === "resolve" ? " vyřešená" : " znovu otevřena") + ".");
      setToggleLoading(false);
      revalidator.revalidate();
    }
  };

  return (
    <>
      <Drawer
        position="right"
        title="Přehled škod"
        smallTitle={room.name}
        icon={IconTool}
        size={800}
        opened={opened}
        onClose={onClose}>
        {user.permissions.includes("accommodation.rooms.issues.create") && (
          <Button
            fullWidth
            onClick={() => {
              openRoomIssueModal("Přidání škody", room, room.name);
            }}
            mb="md">
            Přidat škodu
          </Button>
        )}

        {room.issues.map((issue) => {
          return (
            <Card mb="md" className={classNames({ "opacity-50": issue.resolvedAt })}>
              <Group justify="space-between">
                <Text c="dimmed" size="sm">
                  {dayjs(issue.createdAt).format("D. M. YYYY HH:mm")}
                </Text>
                {!issue.isPermanent ? (
                  <Tooltip
                    position="top"
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label={issue.resolvedAt ? "Znovu otevřít" : "Vyřešit"}>
                    <ActionIcon
                      variant="light"
                      loading={toggleLoading}
                      color={issue.resolvedAt ? "red" : "teal"}
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleStatus(issue);
                      }}>
                      {issue.resolvedAt === null ? (
                        <IconCheck size={18} stroke={1.5} />
                      ) : (
                        <IconArrowBackUp stroke={1.5} size={18} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                ) : (
                  <Text span c="dimmed" size="sm">
                    Trvalá škoda
                  </Text>
                )}
              </Group>

              <Text mb={issue.images.length > 0 ? "md" : 0}>{issue.text}</Text>
              {issue.images.length > 0 && (
                <Gallery
                  thumbClassname="w-[150px] h-[150px] rounded-md object-cover object-center"
                  images={issue.images.map((image) => {
                    return { src: image };
                  })}
                />
              )}
            </Card>
          );
        })}
      </Drawer>
    </>
  );
}
