import Table from "./core/Table.jsx";

import { ActionIcon, Divider, Group, Text, Tooltip } from "@mantine/core";
import {
  IconBed,
  IconCash,
  IconDoorExit,
  IconFile,
  IconFilePlus,
  IconFileText,
  IconHeartHandshake,
  IconId,
  IconIdBadge2,
  IconShirt,
  IconStarFilled,
  IconTent,
  IconVirus,
} from "@tabler/icons-react";
import { parseFullName } from "../../helpers/helpers.js";
import { Link, useRevalidator, useSearchParams } from "react-router-dom";
import { sortChildren } from "./sort.js";
import { useUser } from "../../providers/UserProvider.jsx";
import dayjs from "dayjs";
import { IconCloudCheck } from "@tabler/icons-react";
import classNames from "classnames";

export default function BoardingOverviewTable({ accreditationList }) {
  const { user } = useUser();
  const [queryParams, setQueryParams] = useSearchParams();
  const permissions = user.permissions;

  const filterConfig = [
    {
      key: "doprava",
      label: "Pouze bus",
      type: "switch",
      defaultValue:
        (queryParams.get("type") === "bus" || permissions.includes("fe.delegate")) &&
        permissions.includes("fe.delegate.filter"),
      unchangeable: !permissions.includes("fe.delegate.filter"),
      function: (child) => child.arriveByBus,
    },
    {
      key: "jmeno",
      label: "Jméno",
      type: "search",
      function: (child, value) => parseFullName(child).toLowerCase().includes(value.toLowerCase()),
    },
    /* {
      key: "hasArrived",
      type: "select",
      label: "Přijelo",
      options: [
        {
          label: "Ano",
          value: true,
        },
        {
          label: "Ne",
          value: false,
        },
      ],
      function: (child, value) => {
        console.log(value && child.room)
        return value && child.room
      },
    }, */
  ];

  const stateButtons = (child) => {
    const merchPermissions = ["merch", "children.child.merch"];
    const medicalPermissions = ["medical"];
    const documentsPermissions = ["children.child.documents", "money"];
    const accomodationPermissions = [
      "accommodation.rooms.index",
      "accommodation.rooms.update",
      "staff.user.accommodate",
      "children.child.accommodate",
    ];

    return (
      <Group wrap="nowrap" gap="xs">
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={
            child.medicalCertificate?.status === "core"
              ? "LP evidováno online"
              : child.medicalCertificate?.status === "paper"
              ? "LP předáno papírově"
              : "LP nepředáno"
          }>
          <ActionIcon
            color={child.medicalCertificate ? (child.medicalCertificate.status === "paper" ? "yellow" : "teal") : "red"}
            variant="filled"
            component={Link}
            className={classNames("overflow-visible")}
            to={
              permissions.some((p) => medicalPermissions.indexOf(p) >= 0) && "/akreditace/zdravotnik?child=" + child.id
            }>
            {child?.medicalCertificate?.original && (
              <IconStarFilled
                stroke={1.5}
                size={16}
                className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
              />
            )}
            {child.medicalCertificate?.status === "core" && <IconCloudCheck stroke={1.5} size={20} />}
            {!child.medicalCertificate && <IconFilePlus stroke={1.5} size={20} />}
            {child.medicalCertificate?.status === "paper" && <IconFileText stroke={1.5} size={20} />}
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={
            child.insuranceCard?.status === "core"
              ? "KP evidována online"
              : child.insuranceCard?.status === "paper"
              ? "KP předáno papírově"
              : "KP nepředáno"
          }>
          <ActionIcon
            color={child.insuranceCard ? (child.insuranceCard.status === "paper" ? "yellow" : "teal") : "red"}
            variant="filled"
            className={classNames("overflow-visible")}
            component={Link}
            to={
              permissions.some((p) => medicalPermissions.indexOf(p) >= 0) && "/akreditace/zdravotnik?child=" + child.id
            }>
            {child?.insuranceCard?.original && (
              <IconStarFilled
                stroke={1.5}
                size={16}
                className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
              />
            )}
            {child.insuranceCard?.status === "core" && <IconCloudCheck stroke={1.5} size={20} />}
            {!child.insuranceCard && <IconIdBadge2 stroke={1.5} size={20} />}
            {child.insuranceCard?.status === "paper" && <IconFileText stroke={1.5} size={20} />}
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={
            child.identityCard?.status === "core"
              ? "OP/pas evidován online"
              : child.identityCard?.status === "paper"
              ? "OP/pas předáno papírově"
              : "OP/pas nepředáno"
          }>
          <ActionIcon
            color={child.identityCard ? "teal" : "red"}
            variant="filled"
            className={classNames("overflow-visible")}
            component={Link}
            to={
              permissions.some((p) => medicalPermissions.indexOf(p) >= 0) && "/akreditace/zdravotnik?child=" + child.id
            }>
            {child?.identityCard?.original && (
              <IconStarFilled
                stroke={1.5}
                size={16}
                className="absolute -left-[8px] -top-[8px] text-yellow-100 drop-shadow-md"
              />
            )}
            {child.identityCard?.status === "core" && <IconCloudCheck stroke={1.5} size={20} />}
            {!child.identityCard && <IconId stroke={1.5} size={20} />}
            {child.identityCard?.status === "paper" && <IconFileText stroke={1.5} size={20} />}
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.infectiousness ? "Bezinfekčnost podepsána" : "Bezinfekčnost nepodepsána"}>
          <ActionIcon
            color={child.infectiousness ? "teal" : "red"}
            variant="filled"
            component={Link}
            to={
              permissions.some((p) => medicalPermissions.indexOf(p) >= 0) && "/akreditace/zdravotnik?child=" + child.id
            }>
            <IconVirus stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Divider orientation="vertical" />
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.campRules ? "TŘ podepsán" : "TŘ nepodepsán"}>
          <ActionIcon
            color={child.campRules ? "teal" : "red"}
            variant="filled"
            component={Link}
            to={
              permissions.some((p) => documentsPermissions.indexOf(p) >= 0) &&
              "/akreditace/rady-a-kauce?child=" + child.id
            }>
            <IconTent stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Předání dítěte jiné osobě">
          <ActionIcon
            variant="filled"
            disabled={!child.handover}
            color="teal"
            component={Link}
            to={
              permissions.some((p) => documentsPermissions.indexOf(p) >= 0) &&
              "/akreditace/rady-a-kauce?child=" + child.id
            }>
            <IconHeartHandshake stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={
            child.deposit.handed
              ? `${
                  child.deposit.amount.czk
                    ? `${child.deposit.amount.czk} CZK${child.deposit.amount.eur ? ", " : ""}`
                    : ""
                }${child.deposit.amount.eur ? child.deposit.amount.eur + " EUR" : ""}`
              : "Kauce nepředána"
          }>
          <ActionIcon
            variant="filled"
            color={child.deposit.handed ? "teal" : "red"}
            component={Link}
            to={
              permissions.some((p) => documentsPermissions.indexOf(p) >= 0) &&
              "/akreditace/rady-a-kauce?child=" + child.id
            }>
            <IconCash stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Divider orientation="vertical" />
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.isAccommodated ? (child.roomReservation ? "Má rezervaci pokoje" : "Ubytováno") : "Nemá pokoj"}>
          <ActionIcon
            variant="filled"
            component={Link}
            to={permissions.some((p) => accomodationPermissions.indexOf(p) >= 0) && "/ubytovani/prirazeni"}
            color={child.isAccommodated ? (child.roomReservation ? "orange" : "teal") : "red"}>
            <IconBed stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.merch.filter((merch) => !merch.delivered).length > 0 ? "Merch nepředán" : "Merch předán"}>
          <ActionIcon
            disabled={child.merch.length === 0}
            color={child.merch.filter((merch) => !merch.delivered).length > 0 ? "red" : "teal"}
            variant="filled"
            component={Link}
            to={permissions.some((p) => merchPermissions.indexOf(p) >= 0) && "/akreditace/merch?child=" + child.id}>
            <IconShirt stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
        <Divider orientation="vertical" />
        <Tooltip
          position="top"
          withArrow
          transition="fade"
          transitionDuration={200}
          label={child.hasLeft ? "Dítě odjelo" : "Dítě neodjelo"}>
          <ActionIcon
            color={child.hasLeft ? "teal" : "red"}
            variant="filled"
            component={Link}
            /* to={
              permissions.some((p) => medicalPermissions.indexOf(p) >= 0) && "/akreditace/zdravotnik?child=" + child.id
            } */
          >
            <IconDoorExit stroke={1.5} size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno",
      render: (child) => (
        <div>
          <Text span color={child.hasLeft && "dimmed"}>
            {parseFullName(child)}
          </Text>
          {child.hasLeft ? (
            <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label="Dítě odjelo">
              <Text h={18} span color="red.6">
                <IconDoorExit stroke={1.5} size={18} />
              </Text>
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
      sortable: true,
    },
    {
      accessor: "age",
      title: "Věk",
      render: (child) => {
        return (
          <Text color={child.hasLeft && "dimmed"}>
            {dayjs(child.birthDate).format("D. M. YYYY")} ({child.age})
          </Text>
        );
      },
      sortable: true,
    },
    {
      accessor: "room",
      title: permissions.includes("fe.delegate") ? "Město" : "Pokoj",
      render: (child) => (
        <Text color={child.hasLeft && "dimmed"}>
          {permissions.includes("fe.delegate") ? child.transportCity : child.room}
        </Text>
      ),
      sortable: true,
    },
    {
      accessor: "actions",
      title: (
        <Group gap={"xs"}>
          <Text span className="min-w-[1.75rem]" align="center" fw="bold">
            LP
          </Text>
          <Text span className="min-w-[1.75rem]" align="center" fw="bold">
            KP
          </Text>
          <Text span className="min-w-[1.75rem]" align="center" fw="bold">
            OP
          </Text>
          <Text span className="min-w-[1.75rem]" align="center" fw="bold">
            BI
          </Text>
          <Divider orientation="vertical" />
          <Text span className="min-w-[1.75rem]" align="center" fw="bold">
            Ostatní stavy
          </Text>
        </Group>
      ),
      subtitle: "Stavy dokumentů",
      render: (child) => stateButtons(child),
      narrow: true,
    },
  ];

  return (
    <Table
      id="akreditace-prehled"
      filterConfig={filterConfig}
      columns={columns}
      records={accreditationList}
      sortFunction={sortChildren}
    />
  );
}
