import { ActionIcon, Button, Group, Input, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useRevalidator } from "react-router";
import { useFetch } from "../../helpers/useFetch";
import { dosageOptions } from "../../helpers/dosages";
import dayjs from "dayjs";

const MedicationTakeModal = ({ innerProps, context, id }) => {
  const { child, period, date } = innerProps;
  const [medicationArray, setMedicationArray] = useState([]);
  const [medicationStates, setMedicationStates] = useState([]);
  const [savedChanges, setSavedChanges] = useState(true);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const revalidator = useRevalidator();

  const findDosages = (type) => {
    const dosages = [];
    dosageOptions.forEach((dose) => {
      if (dose.type.includes(type[0])) {
        dosages.push(dose);
      }
    });
    return dosages;
  };

  useEffect(() => {
    const medicationsTmp = [];
    const statesTmp = [];
    const dosages = findDosages(period);
    child.medicalMedications.forEach((medication) => {
      dosages.map((dose) => {
        statesTmp.push({ id: medication.id, taked: medication[dose.type].taked, dosePeriod: dose.type });
        if (medication[dose.type].taked) {
          medicationsTmp.push({ id: medication.id, taked: true, dosePeriod: dose.type });
        }
      });
    });
    setMedicationStates(statesTmp);
    setMedicationArray(medicationsTmp);
  }, [child]);

  const handleStateChange = (medicationId, state, dosePeriod) => {
    const id = medicationArray.findIndex(
      (medication) => medication.id === medicationId && medication.dosePeriod === dosePeriod
    );
    const defaultState = medicationStates.find(
      (medication) => medication.id === medicationId && medication.dosePeriod === dosePeriod
    );
    const medicationTmp = medicationArray;
    if (id >= 0) {
      if (defaultState && defaultState.taked === state) {
        medicationTmp.splice(id, 1);
      } else {
        medicationTmp[id].taked = state;
      }
      child.medicalMedications.find((medication) => medication.id === medicationId)[dosePeriod].taked = state;
    } else {
      child.medicalMedications.find((medication) => medication.id === medicationId)[dosePeriod].taked = true;
      medicationTmp.push({ id: medicationId, taked: state, dosePeriod: dosePeriod });
    }
    setUpdate(!update);
    setSavedChanges(false);
    setMedicationArray(medicationTmp);
  };

  const form = useForm();

  const handleOneChange = async (medication) => {
    const url = "medical/child-medications/record/";
    const medicationDefaultState = medicationStates.find(
      (med) => med.id === medication.id && med.dosePeriod === medication.dosePeriod
    );
    if (medication.taked === medicationDefaultState.taked) return;
    const METHOD = medication.taked ? "POST" : "DELETE";
    const res = await useFetch(url + medication.id, METHOD, {
      period: medication.dosePeriod,
      date: dayjs(date).format("YYYY-MM-DD"),
    });
    if (res.message) {
      toast.error(res.message);
      handleStateChange(medication.id, !medication.taked, medication.dosePeriod);
    }
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    if (medicationArray.length < 1) return context.closeModal(id);
    medicationArray.forEach(async (medication, i) => {
      await handleOneChange(medication);
      if (i >= medicationArray.length - 1) {
        setLoading(false);
        revalidator.revalidate();
        toast.success("Záznamy o užití uloženy");
        setSavedChanges(false);
        return context.closeModal(id);
      }
    });
  };

  useEffect(() => {
    if (!savedChanges && context.modals.length < 1) {
      medicationArray.forEach((medication) => {
        const medicationDefaultState = medicationStates.find(
          (med) => med.id === medication.id && med.dosePeriod === medication.dosePeriod
        );
        if (medication.taked !== medicationDefaultState.taked) {
          handleStateChange(medication.id, medicationDefaultState.taked, medicationDefaultState.dosePeriod);
        }
      });
    }
  }, [context.modals]);

  const medicationAmount = (medication) => {
    let text = "Množství";
    if (medication[period[0] + "_m"].amount && !medication[period[0] + "_p"].amount)
      text += ": " + medication[period[0] + "_m"].amount;
    if (medication[period[0] + "_p"].amount && !medication[period[0] + "_m"].amount)
      text += ": " + medication[period[0] + "_p"].amount;
    if (medication[period[0] + "_p"].amount && medication[period[0] + "_m"].amount)
      text += " před: " + medication[period[0] + "_m"].amount + ", po: " + medication[period[0] + "_m"].amount;
    return text;
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Input.Wrapper label="Dítě">
          <TextInput value={child.firstName + " " + child.lastName} readOnly />
        </Input.Wrapper>
        <Group mt={"md"} className="flex w-full flex-col items-start">
          <Text size={"sm"} fw="bold">
            Léky
          </Text>
          <Group className="w-full">
            {child.medicalMedications.map((medication) => {
              return (
                (medication[period[0] + "_m"].amount || medication[period[0] + "_p"].amount) && (
                  <Group className="mt-1 flex w-full justify-between" key={medication.id}>
                    <Group className="flex flex-col items-start justify-center">
                      <Text className="leading-2">{medication.name}</Text>
                      <Text size={"sm"} className="leading-2" c="dimmed">
                        {medicationAmount(medication)} - {medication.description}
                      </Text>
                    </Group>
                    <Group className="flex gap-2">
                      {findDosages(period).map((dose) => {
                        return (
                          <ActionIcon
                            variant="filled"
                            color={medication[dose.type].taked ? "teal" : "red"}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleStateChange(medication.id, !medication[dose.type].taked, dose.type);
                            }}
                            className="font-bold"
                            w={32}
                            h={32}
                            disabled={!medication[dose.type].amount}>
                            {dose.text}
                          </ActionIcon>
                        );
                      })}
                    </Group>
                  </Group>
                )
              );
            })}
          </Group>
        </Group>
        <Button type="submit" variant="filled" mt={"lg"} loading={loading} className="w-full">
          Zaznamenat užití
        </Button>
      </form>
    </>
  );
};

export default MedicationTakeModal;
