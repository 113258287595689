import { ActionIcon, Group, Select, Text, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { openMedicationTakeModal } from "../../helpers/modals";
import Table from "./core/Table";
import { dosageOptions } from "../../helpers/dosages";
import { DatePickerInput } from "@mantine/dates";
import dayjs from "dayjs";
import { useUser } from "../../providers/UserProvider.jsx";
import { IconArrowLeft, IconArrowRight, IconCalendar, IconCalendarEvent, IconClock } from "@tabler/icons-react";
import { useRevalidator, useSearchParams } from "react-router-dom";

const MedicationsTable = ({ medications }) => {
  const [update, setUpdate] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const [date, setDate] = useState(
    searchParams.get("date")
      ? dayjs(searchParams.get("date"), "YYYY-MM-DD").toDate()
      : user?.currentCampSession?.isActual
      ? dayjs()
      : dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()
  );
  const [period, setPeriod] = useState(searchParams.get("period"));
  const [dateOld, setDateOld] = useState(date);
  const revalidator = useRevalidator();

  useEffect(() => {
    if (dateOld !== date) {
      let paramsLocal = searchParams;
      let currentUrl = window.location.href;
      let URLObject = currentUrl.split("?");
      paramsLocal.set("date", dayjs(date).format("YYYY-MM-DD"));
      window.history.replaceState("", "", URLObject[0] + "?" + paramsLocal);
      return revalidator.revalidate();
    }
  }, [date]);

  useEffect(() => {
    setLoading(revalidator.state === "loading");
  }, [revalidator.state]);

  useEffect(() => {
    let paramsLocal = searchParams;
    let currentUrl = window.location.href;
    let URLObject = currentUrl.split("?");
    paramsLocal.set("period", period);
    window.history.replaceState("", "", URLObject[0] + "?" + paramsLocal);
    return revalidator.revalidate();
  }, [period]);

  const setCurrentPeriod = (value) => {
    if (value !== "automatic") return setPeriod(value);
    const hours = new Date().getHours();
    if (hours < 11) {
      setPeriod("s");
    } else if (hours < 16) {
      setPeriod("o");
    } else if (hours < 25) {
      setPeriod("v");
    }
  };

  const refactorMedications = () => {
    const periods = ["s_m", "s_p", "o_m", "o_p", "v_m", "v_p"];
    medications.map((child) => {
      const timeState = [];
      periods.map((time) => {
        const periodState = [];
        let isTaking = false;
        const dosage = findDosage(time);
        child.medicalMedications.map((medication) => {
          const medicationState = [];
          if (medication[dosage.type].amount) {
            medicationState.push(medication[dosage.type].taked);
            periodState.push(medicationState.reduce((a, b) => a || b));
            isTaking = true;
          }
        });
        periodState.length < 1 && periodState.push(false);
        timeState.push({ period: time, isTaking, taked: periodState.reduce((a, b) => a && b) });
        if (!child[time[0]]) child[time[0]] = isTaking;
      });
      child.refactoredMedications = timeState;
    });
  };

  useEffect(() => {
    if (!period) setCurrentPeriod("automatic");
    refactorMedications();
  }, []);

  useEffect(() => {
    if (!period) setCurrentPeriod("automatic");
    refactorMedications();
    setUpdate(!update);
  }, [medications]);

  const periodOptions = [
    {
      label: "Snídaně",
      value: "s",
    },
    {
      label: "Oběd",
      value: "o",
    },
    {
      label: "Večeře",
      value: "v",
    },
    {
      label: "Celý den",
      value: "all",
    },
  ];

  const findDosage = (type) => {
    return dosageOptions.find((dose) => dose.type === type);
  };

  const findDosages = (type) => {
    const dosages = [];
    dosageOptions.forEach((dose) => {
      if (type === "all") {
        dosages.push(dose);
      } else if (dose.type.includes(type[0])) {
        dosages.push(dose);
      }
    });
    return dosages;
  };

  const childName = (child) => {
    return (
      <div>
        <strong>
          {child.firstName}&nbsp;{child.lastName} <span className="mobileAge">({child.age})</span>
        </strong>
      </div>
    );
  };

  const medicationName = (medications) => {
    let first = true;
    return (
      <div>
        {medications?.map((medication) => {
          let typed = false;
          return findDosages(period ? period : "all").map((dose) => {
            if (!medication[dose.type].amount || typed) return;
            typed = true;
            if (first) {
              first = false;
              return medication?.name;
            }
            return ", " + medication?.name;
          });
        })}
      </div>
    );
  };

  const team = (team) => {
    if (!team) {
      return <div>Nezařezen</div>;
    }

    return (
      <div>
        {team.name} {team.number}
        {team?.leader && " - " + team.leader.firstName + " " + team.leader.lastName}
      </div>
    );
  };

  const actionButton = (child, period, medication) => {
    return (
      <Tooltip position="top" withArrow transition="fade" transitionDuration={200} label={"Stav léků"}>
        <ActionIcon
          variant="filled"
          color={medication.taked ? "teal" : "red"}
          onClick={(e) => {
            e.stopPropagation();
            openMedicationTakeModal("Záznam užití léku", child, period.type, date);
          }}
          disabled={!medication.isTaking}>
          <strong>{period.text}</strong>
        </ActionIcon>
      </Tooltip>
    );
  };

  const actionButtons = (child, medications) => {
    return (
      <Group wrap="nowrap" gap="xs">
        {medications?.map((medication) => {
          return period === medication.period[0]
            ? actionButton(child, findDosage(medication.period), medication)
            : period === "all" && actionButton(child, findDosage(medication.period), medication);
        })}
      </Group>
    );
  };

  const columns = [
    {
      accessor: "name",
      title: "Jméno dítěte",
      render: (child) => childName(child),
      sortable: true,
    },
    {
      accessor: "medication",
      title: "Lék",
      render: (medication) => medicationName(medication.medicalMedications),
      sortable: true,
    },
    {
      accessor: "team",
      title: "Oddíl - vedoucí",
      render: (child) => team(child.team),
      sortable: true,
    },
    {
      accessor: "room",
      title: "Pokoj",
      render: (child) => child?.room?.name,
    },
    {
      accessor: "actionButtons",
      title: "Akce",
      render: (child) => actionButtons(child, child.refactoredMedications),
      narrow: true,
    },
  ];

  return (
    <>
      <Group gap="xl" mb="md">
        <Group wrap="nowrap" gap="xs">
          <Text fw="bold">Doba dne:</Text>
          <Select
            value={period}
            leftSection={<IconClock size={16} stroke={1.5} />}
            onChange={(value) => {
              setCurrentPeriod(value);
            }}
            data={periodOptions}
            disabled={loading}
          />
        </Group>
        <Group wrap="nowrap" gap="xs">
          <Text fw="bold">Den:</Text>

          <DatePickerInput
            locale="cs"
            disabled={loading}
            valueFormat="D. MMMM YYYY"
            leftSection={<IconCalendar size={16} stroke={1.5} />}
            minDate={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
            maxDate={dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD").toDate()}
            initialMonth={dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD").toDate()}
            rightSection={
              <Group wrap="nowrap" gap={0}>
                <ActionIcon
                  onClick={() => {
                    if (dayjs(user?.currentCampSession.dateFrom, "YYYY-MM-DD") < dayjs(date))
                      setDate(dayjs(date).subtract(1, "day"));
                  }}
                  size="sm"
                  color="gray"
                  variant="subtle">
                  <IconArrowLeft size={16} stroke={1.5} />
                </ActionIcon>
                <ActionIcon
                  onClick={() => {
                    if (dayjs(user?.currentCampSession.dateTo, "YYYY-MM-DD") > dayjs(date))
                      setDate(dayjs(date).add(1, "day"));
                  }}
                  size="sm"
                  color="gray"
                  variant="subtle">
                  <IconArrowRight size={16} stroke={1.5} />
                </ActionIcon>
              </Group>
            }
            rightSectionWidth={58}
            w={250}
            value={date}
            onChange={setDate}
            clearable={false}
            required
          />
        </Group>
      </Group>

      <Table
        loading={true}
        id="pravidelne-leky"
        columns={columns}
        records={period === "all" || !period ? medications : medications.filter((child) => child[period])}
      />
    </>
  );
};

export default MedicationsTable;
