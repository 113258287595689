import {
  ActionIcon,
  Button,
  Checkbox,
  Select,
  Text,
  Tooltip,
  localStorageColorSchemeManager,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { IconCheck, IconMoon } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useUser } from "../../providers/UserProvider.jsx";
import { useRevalidator } from "react-router-dom";

export default function AppearanceModal({ context, id, innerProps }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedColor, setSelectedColor] = useState("red");
  const [localColor, setLocalColor] = useLocalStorage({ key: "color", defaultValue: "indigo" });
  const { colorScheme, setColorScheme } = useMantineColorScheme();
  const [rainbowMode, setRainbowMode] = useLocalStorage({ key: "rainbow", defaultValue: "disable" });
  const [rainbow, setRainbow] = useState(false);
  const { user } = useUser();

  const theme = useMantineTheme();

  const revalidator = useRevalidator();

  const [selectedTheme, setSelectedTheme] = useState(colorScheme || "system");

  const submit = () => {
    setLoading(true);
    setLocalColor(selectedColor);
    setColorScheme(selectedTheme);
    setLoading(false);
    localStorage.setItem("rainbow", rainbow ? "enable" : "disable");
    const body = document.querySelector("body");
    if (rainbow) {
      body.classList.add("rainbow");
    } else {
      body.classList.remove("rainbow");
    }
    window.location.reload();
  };

  useEffect(() => {
    setSelectedColor(localColor);
  }, [localColor]);

  useEffect(() => {
    setRainbow(rainbowMode === "enable");
  }, [rainbowMode]);

  const colors = [
    { label: "Červená", value: "red" },
    { label: "Růžová", value: "pink" },
    { label: "Fialová", value: "grape" },
    { label: "Modrá", value: "indigo" },
    { label: "Azurová", value: "cyan" },
    { label: "Zelená", value: "green" },
    { label: "Limetková", value: "lime" },
    { label: "Žlutá", value: "yellow" },
    { label: "Oranžová", value: "orange" },
  ];

  return (
    <>
      <Select
        data={[
          { label: "Světlý režim", value: "light" },
          { label: "Tmavý režim", value: "dark" },
          { label: "Podle systému", value: "auto" },
        ]}
        value={selectedTheme}
        onChange={setSelectedTheme}
        label="Tmavý/světlý režim"
        placeholder="Vyber režim..."
        leftSection={<IconMoon size={16} />}
      />

      <Text fw="bold" size="sm" mb={4} mt="sm">
        Barevný motiv
      </Text>
      <div className="flex justify-between">
        {colors.map((color) => (
          <div>
            <Tooltip withArrow label={color.label} position="top">
              <ActionIcon
                color={color.value}
                size="xl"
                variant={selectedColor === color.value ? "filled" : "light"}
                onClick={() => setSelectedColor(color.value)}>
                {selectedColor === color.value && <IconCheck stroke={1.5} size={28} />}
              </ActionIcon>
            </Tooltip>
          </div>
        ))}
      </div>

      {user.permissions.includes("fe.founders") && (
        <>
          <Text fw="bold" size="sm" mb={4} mt="sm">
            Founders motiv
          </Text>
          <Checkbox
            checked={rainbow}
            onChange={() => {
              setRainbow(!rainbow);
            }}
            label="Speciální founders motiv"
          />
        </>
      )}

      <Button fullWidth type="submit" loading={loading} onClick={() => submit()} mt="md">
        Uložit nastavení
      </Button>
    </>
  );
}
