import { ActionIcon, Group, Select, Text, Tooltip } from "@mantine/core";
import { IconBus, IconCheck, IconClock, IconWalk } from "@tabler/icons-react";
import classNames from "classnames";
import { useEffect } from "react";
import { useState } from "react";
import { forwardRef } from "react";
import RichSelect from "../../RichSelect";
import { useColorScheme } from "@mantine/hooks";
import { getMode } from "../../../helpers/helpers";

export default function RoomField({ changeKid, room, childrenArray, index, child, changeReservation, roomGender }) {
  const [search, setSearch] = useState("");
  const [localChildrenArray, setLocalChildrenArray] = useState(
    childrenArray.filter((child) => {
      if (!roomGender || roomGender === child.gender) {
        return true;
      } else {
        return false;
      }
    })
  );

  useEffect(() => {
    setLocalChildrenArray(
      childrenArray.filter((child) => {
        if (!roomGender || roomGender === child.gender) {
          return true;
        } else {
          return false;
        }
      })
    );
  }, [childrenArray, roomGender]);

  const { mode } = getMode();

  return (
    <Group gap="xs" wrap="nowrap" key={`roomField-${room.id}-${index}`}>
      {!child?.id && (
        <Tooltip label="Žádné ubytované dítě" position="right">
          <ActionIcon variant="filled" size="lg" color="gray" />
        </Tooltip>
      )}

      {child?.id && child.roomReservation && !child.arriveByBus && (
        <Tooltip label="Přijede po vlastní ose, má rezervaci" position="right">
          <ActionIcon variant="filled" color="orange" onClick={() => changeReservation(child)} size="lg">
            <IconWalk size={24} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      )}

      {child?.id && child.roomReservation && child.arriveByBus && (
        <Tooltip label="Přijede autobusem, má rezervaci" position="right">
          <ActionIcon variant="filled" color="orange" onClick={() => changeReservation(child)} size="lg">
            <IconBus size={24} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      )}

      {child?.id && !child.roomReservation && (
        <Tooltip label="Dítě ubytováno" position="right">
          <ActionIcon variant="filled" color="green" onClick={() => changeReservation(child)} size="lg">
            <IconCheck size={24} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      )}

      {/* <Select
        className="grow"
        onChange={(value) => {
          changeKid(value, index);
        }}
        value={String(child?.id)}
        searchable
        hoverOnSearchChange={true}
        clearable
        searchValue={search}
        onSearchChange={setSearch}
        itemComponent={SelectItem}
        data={localChildrenArray}
      /> */}
      <RichSelect
        data={localChildrenArray}
        value={String(child?.id)}
        searchable
        className="grow"
        label={(data) => {
          return (
            <Text c={data?.gender === "Dívka" ? "pink.6" : mode === "dark" ? "white" : "black"}>{data?.label}</Text>
          );
        }}
        onChange={(value) => {
          changeKid(value, index);
        }}
        placeholder="Vyber dítě..."
      />
    </Group>
  );
}
