import { useLoaderData, useRevalidator } from "react-router-dom";
import Page from "../../components/layout/Page";
import { useFetch } from "../../helpers/useFetch";
import { Tilt } from "react-tilt";
import { getMode } from "../../helpers/helpers";
import { useLocalStorage } from "@mantine/hooks";
import { useContext, useEffect, useState } from "react";
import { ActionIcon, Button, Group, Select, Text, TextInput, Tooltip } from "@mantine/core";
import { IconAt, IconPhone, IconX, IconBrandDiscord } from "@tabler/icons-react";
import SaveChangesBar from "../../components/SaveChangesBar";
import { useForm } from "@mantine/form";
import { DateInput } from "@mantine/dates";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import useTitle from "../../hooks/useTitle";
import { openUserSignatureUpdateModal } from "../../helpers/modals.js";
import { UserContext } from "../../providers/UserProvider";

export const loader = () => {
  return useFetch(`user/details`);
};

const defaultOptions = {
  reverse: false, // reverse the tilt direction
  max: 15, // max tilt rotation (degrees)
  perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.05, // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000, // Speed of the enter/exit transition
  transition: true, // Set a transition on enter/exit.
  axis: null, // What axis should be disabled. Can be X or Y.
  reset: true, // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
};

const roles = [
  "Průzkumník",
  "Cestovatel",
  "Lovec pokladů",
  "Horský průvodce",
  "Potápěč",
  "Expediční lékař",
  "Lesní strážce",
  "Geolog",
  "Filmový kaskadér",
  "Kajakářský průvodce",
  "Záchranář v horách",
  "Štítový ochránce",
  "Fotograf na safari",
  "Průvodce v divočině",
  "Záchranný pracovník",
  "Pilot letadla na expedicích",
  "Výzkumník živočichů",
  "Archeolog",
  "Průvodce ledovcovou oblastí",
  "Výzkumník elementů",
];

export default function UserSettingsPage() {
  const [user, setUser] = useState(useLoaderData().data);
  const userData = useContext(UserContext);
  const [title, setTitle] = useLocalStorage({ key: "member-title", defaultValue: "" });
  const [membersCardModal, setMembersCardModal] = useState(false);
  const [editBasic, setEditBasic] = useState(false);
  const [basicLoading, setBasicLoading] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const { mode, theme } = getMode();
  const [date, setDate] = useState(user.birthDate ? new Date(user.birthDate) : null);
  const [healthInsuranceCompanies, setHealthInsuranceCompanies] = useState([]);
  const revalidator = useRevalidator();
  useTitle("Nastavení uživatele");

  useEffect(() => {
    if (title === "") {
      let randomRoleNumber = Math.floor(Math.random() * roles.length);
      setTitle(roles[randomRoleNumber]);
    }
  }, [title]);

  useEffect(() => {
    setAddressLoading(true);
    useFetch(`public/codebook/health-insurance-companies`).then((res) => {
      setHealthInsuranceCompanies(res.data);
    });
    setAddressLoading(false);
  }, []);

  const basicForm = useForm({
    initialValues: {
      jmeno: user.firstName,
      prijmeni: user.lastName,
      email: user.email,
      DORtelefon: user.phone,
    },
  });

  const addressForm = useForm({
    initialValues: {
      DORulice: user.address.street,
      DORmesto: user.address.city,
      DORpsc: user.address.zip,
      narozeni: user.birthDate,
      rc: user.nationalIdNumber,
      cislo_op: user.nationalIdCardNumber,
      cislo_ucet: user.bankAccountNumber,
      narozeni_misto: user.birthPlace,
      health_insurance_company_id: user.healthInsuranceCompany?.id.toString(),
    },
  });

  const saveBasic = (values) => {
    setBasicLoading(true);
    useFetch("/user", "PUT", { ...values, narozeni: date && dayjs(date).format("YYYY-MM-DD") })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Základní informace byly úspěšně uloženy.");
          setUser({
            ...user,
            firstName: values.jmeno,
            lastName: values.prijmeni,
            phone: values.DORtelefon,
            email: values.email,
          });
          setBasicLoading(false);
          setEditBasic(false);
          basicForm.resetDirty(basicForm.values);
          revalidator.revalidate();
        }
      })
      .catch((err) => {
        toast.error("Nastala chyba při ukládání uživatele a byla nahlášena.");
        console.error(err);
        setBasicLoading(false);
      });
  };

  const saveAddress = (values) => {
    setAddressLoading(true);
    if (date) {
      values.narozeni = dayjs(date).format("YYYY-MM-DD");
    }
    console.log(values.narozeni);
    useFetch("/user", "PUT", { ...values, jmeno: user.firstName, prijmeni: user.lastName })
      .then((res) => {
        if (res.status === "ok") {
          toast.success("Informace do smlouvy byly úspěšně uloženy.");
          setUser({
            ...user,
            address: {
              street: values.DORulice,
              city: values.DORmesto,
              zip: values.DORpsc,
            },
            birthDate: values.narozeni,
            nationalIdNumber: values.rc,
            nationalIdCardNumber: values.cislo_op,
            bankAccountNumber: values.cislo_ucet,
            birthPlace: values.narozeni_misto,
          });
          setAddressLoading(false);
          addressForm.resetDirty(addressForm.values);
          revalidator.revalidate();
        }
      })
      .catch((err) => {
        toast.error("Nastala chyba při ukládání uživatele a byla nahlášena.");
        console.error(err);
        setAddressLoading(false);
      });
  };

  const copyDiscordCode = () => {
    navigator.clipboard.writeText(`${user.discord.code}`);
    toast.success("Kód byl zkopírován do schránky.");
  };

  return (
    <Page title="Nastavení uživatele">
      <div className="flex flex-col items-start gap-8 md:flex-row">
        <div>
          <img src={user.thumbnail} alt="Profilová fotka" className="w-full rounded-md" />
        </div>
        {!editBasic ? (
          <div className="grow">
            <h3 className="text-3xl">
              {user.firstName} {user.lastName}
            </h3>
            <p>{user.position}</p>

            {user.discord.id !== null ? (
              <div className="flex">
                <Text span c="green">
                  Propojeno
                </Text>
                <Text span c="gray">
                  &nbsp; ({user.discord.username})
                </Text>
              </div>
            ) : (
              <div className="flex">
                <Tooltip
                  position="top"
                  withArrow
                  transition="fade"
                  transitionDuration={200}
                  onClick={() => copyDiscordCode()}
                  label={"Klikni pro zkopírování kódu"}>
                  <div className={"flex"}>
                    <IconBrandDiscord stroke={1.5} className={"mr-2"} />
                    <div>
                      <Text span c="red">
                        Nepropojeno
                      </Text>
                      <Text span c="gray">
                        &nbsp; kód: {user.discord.code}
                      </Text>
                    </div>
                  </div>
                </Tooltip>
              </div>
            )}

            <p className="mt-3">{user.email}</p>
            <p>{user.phone}</p>
            <Group mt="md" gap="xs">
              <Button onClick={() => setEditBasic(true)}>Upravit základní informace</Button>
              <Button
                onClick={() => openUserSignatureUpdateModal()}
                variant="light"
                className={userData.user?.permissions?.includes("user.update-signature") ? "" : "hidden"}>
                Nastavit podpis
              </Button>
            </Group>
          </div>
        ) : (
          <div className="grow">
            <form onSubmit={basicForm.onSubmit((values) => saveBasic(values))}>
              <TextInput
                disabled={basicLoading}
                label="Jméno"
                className="w-full"
                {...basicForm.getInputProps("jmeno")}
                required
              />
              <TextInput
                disabled={basicLoading}
                label="Přijmení"
                mt="sm"
                className="w-full"
                {...basicForm.getInputProps("prijmeni")}
                required
              />
              <TextInput
                disabled={basicLoading}
                leftSection={<IconPhone size={18} stroke={1.5} />}
                label="Telefon"
                mt="sm"
                className="w-full"
                {...basicForm.getInputProps("DORtelefon")}
                required
              />
              <TextInput
                leftSection={<IconAt size={18} stroke={1.5} />}
                type="email"
                disabled
                description="E-mail momentálně nelze měnit přímo v Panelu. Kdybys ho chtěl/a změnit, dej vědět hlavnímu vedoucímu."
                label="E-mail"
                mt="sm"
                className="w-full"
                {...basicForm.getInputProps("email")}
              />
              <Group mt={"md"} gap={"sm"} grow>
                <Button type="submit" loading={basicLoading} disabled={basicLoading} className="max-w-none">
                  Uložit základní informace
                </Button>
                <ActionIcon
                  size={36}
                  onClick={() => setEditBasic(false)}
                  variant="light"
                  color="red"
                  className="w-fit grow-0">
                  <IconX size={20} stroke={1.5} />
                </ActionIcon>
              </Group>
            </form>
          </div>
        )}
      </div>
      <form onSubmit={addressForm.onSubmit((values) => saveAddress(values))}>
        <div className="flex grow flex-col items-center gap-8 md:flex-row">
          <div className="w-full">
            <TextInput
              type="text"
              label="Ulice"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("DORulice")}
              required
            />
            <TextInput
              type="text"
              label="Město"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("DORmesto")}
              required
            />
            <TextInput
              type="text"
              label="PSČ"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("DORpsc")}
              required
            />
            <TextInput
              type="text"
              label="Místo narození"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("narozeni_misto")}
              required
            />
          </div>
          <div className="w-full">
            <DateInput
              locale="cs"
              label="Datum narození"
              value={date}
              mt="sm"
              onChange={setDate}
              valueFormat="D. M. YYYY"
              clearable={false}
              required
            />
            <TextInput
              type="text"
              label="Rodné číslo"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("rc")}
              required
            />
            <TextInput
              type="text"
              label="Číslo OP"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("cislo_op")}
              required
            />
            <TextInput
              type="text"
              label="Číslo účtu"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("cislo_ucet")}
              required
            />
            <Select
              label="Zdravotní pojišťovna"
              mt="sm"
              className="w-full"
              {...addressForm.getInputProps("health_insurance_company_id")}
              required
              data={healthInsuranceCompanies.map((item) => ({
                value: item.id.toString(),
                label: item.code + " " + item.name,
              }))}
            />
          </div>
        </div>
        <Button mt="lg" type="submit" fullWidth loading={addressLoading}>
          Uložit kontaktní informace
        </Button>
      </form>
      <SaveChangesBar
        text={"Máš nějaké neuložené změny."}
        show={basicForm.isDirty()}
        onConfirm={() => saveBasic(basicForm.values)}
        onCancel={() => basicForm.reset()}
        loading={basicLoading}
      />
    </Page>
  );
}
